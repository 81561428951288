import { useHttpClientContext } from 'og-admin-portal-shared';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import ApplicationCard from './components/ApplicationCard';
import Greetings from './components/Greetings';
import Navbar from './components/Navbar';

const StyledApplicationSection = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0rem;
  margin: 0rem;
`;

const StyledAppContainer = styled.div`
  padding: 1rem 1rem 1rem 4rem;
`;

function App() {
  const { getHttpClient } = useHttpClientContext();
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const NoAccess = 'No applications available. Please request access.';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHttpClient().get('apiBaseUrl', 'applications');
        setApplications(response);
        setIsLoading(false);
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <StyledAppContainer data-testid="app-container" className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <main>
        <Greetings />
        <div data-testid="app-content">
          {isLoading && <p>Loading...</p>}
          {error && <p>Error loading applications: {error}</p>}
          {!isLoading && !error && !applications.length && <p>{NoAccess}</p>}
          {!isLoading && !error && applications.length > 0 && (
            <StyledApplicationSection>
              {applications.map(application => (
                <ApplicationCard key={application.name} application={application} />
              ))}
            </StyledApplicationSection>
          )}
        </div>
      </main>
    </StyledAppContainer>
  );
}

export default App;
