import { IconNames, SvgIcon } from '@worldline/gaia';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from 'styled-components';

const StyledApplicationCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f2f9fc;
  padding: 1rem;
  width: 210px;
  height: 120px;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledApplicationName = styled.h5`
  margin: 5px;
  color: #575665;
  height: 20px;
`;

const StyledApplicationDescription = styled.p`
  color: #8b8b9a;
  margin: 0px;
  text-align: center;
  padding: 0px;
  font-size: 0.8rem;
  height: 30px;
  margin-bottom: 10px;
`;

function ApplicationCard({ application: { name, description, url, iconName } }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
      <StyledApplicationCard>
        <SvgIcon name={IconNames[iconName]} color="#007b7c" />
        <StyledApplicationName>{name}</StyledApplicationName>
        <StyledApplicationDescription>{description}</StyledApplicationDescription>
      </StyledApplicationCard>
    </a>
  );
}

ApplicationCard.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default ApplicationCard;
