import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

function CustomThemeProvider({ children, branding }) {
  const [theme, setTheme] = useState(null);
  const [loading, setLoading] = useState(true);

  const brand = branding || 'ogone';

  useEffect(() => {
    fetch(`/themes/${brand.toLowerCase()}/theme.json`, {
      cache: 'no-cache'
    })
      .then(response => response.json())
      .then(setTheme)
      .catch(() => console.error('Error while fetching theme'))
      .finally(() => setLoading(false));
  }, [brand]);

  if (theme && !loading) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  }

  return null;
}

CustomThemeProvider.propTypes = {
  /** React node to display */
  children: PropTypes.node.isRequired,
  branding: PropTypes.string
};

CustomThemeProvider.defaultProps = {
  branding: null
};

export default CustomThemeProvider;
