import { HttpClientProvider, getSetting } from 'og-admin-portal-shared';
import KeycloakInitializer from 'og-admin-portal-shared/http/KeycloakInitializer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import CustomThemeProvider from './shared/CustomThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
const keycloakUrl = getSetting('keycloakUrl');

root.render(
  <HttpClientProvider>
    <KeycloakInitializer keycloakClientId="gopay_welcome_page" keycloakUrl={keycloakUrl}>
      <React.StrictMode>
        <CustomThemeProvider branding="ogone">
          <App />
        </CustomThemeProvider>
      </React.StrictMode>
    </KeycloakInitializer>
  </HttpClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
