import { IconNames, SvgIcon } from '@worldline/gaia';
import { useHttpClientContext } from 'og-admin-portal-shared';
import React from 'react';
import { styled } from 'styled-components';

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  img {
    width: 275px;
  }
`;

const StyledIconContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StyledRightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

function Navbar() {
  const { keycloak } = useHttpClientContext();
  return (
    <StyledNavbar>
      <img src="/themes/ogone/logo-expanded.svg" alt="logo" />
      <StyledRightSection>
        <StyledIconContainer type="button" onClick={() => keycloak?.logout()}>
          <SvgIcon size="24px" name={IconNames.logout} />
        </StyledIconContainer>
      </StyledRightSection>
    </StyledNavbar>
  );
}

export default Navbar;
