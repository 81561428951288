import { useHttpClientContext } from 'og-admin-portal-shared';
import React from 'react';
import { styled } from 'styled-components';

const StyledUserName = styled.span`
  color: ${props => props.theme.colors.primary.regular};
`;

function Greetings() {
  const { keycloak } = useHttpClientContext();
  const name = keycloak?.tokenParsed?.name || 'Guest';

  return (
    <h3>
      Hello, <StyledUserName>{name} !</StyledUserName>
    </h3>
  );
}

export default Greetings;
